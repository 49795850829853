var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"card mt-5"},[_c('div',{staticClass:"w-100"},[_c('h5',{staticClass:"title-card"},[_c('i',{staticClass:"fas fa-charging-station"}),_vm._v(" Dados do inversor ")])]),_c('div',{staticClass:"w-100 p-3"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Marca"}},[_c('b-form-input',{class:{ 'form-group--error': _vm.$v.payload.brand.$error },attrs:{"state":_vm.$v.payload.brand.$dirty ?
                !_vm.$v.payload.brand.$error : null},model:{value:(_vm.$v.payload.brand.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.brand, "$model", $$v)},expression:"$v.payload.brand.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Descrição"}},[_c('b-form-input',{class:{ 'form-group--error': _vm.$v.payload.description.$error },attrs:{"state":_vm.$v.payload.description.$dirty ?
                !_vm.$v.payload.description.$error : null},model:{value:(_vm.$v.payload.description.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.description, "$model", $$v)},expression:"$v.payload.description.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('b-col',{attrs:{"lg":"3"}},[_c('b-form-group',{attrs:{"label":"Potência (KW)"}},[_c('b-form-input',{class:{ 'form-group--error': _vm.$v.payload.power.$error },attrs:{"state":_vm.$v.payload.power.$dirty ?
                !_vm.$v.payload.power.$error : null},model:{value:(_vm.$v.payload.power.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.power, "$model", $$v)},expression:"$v.payload.power.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('b-col',{attrs:{"lg":"3"}},[_c('b-form-group',{attrs:{"label":"Overload (KWP)"}},[_c('b-form-input',{class:{ 'form-group--error': _vm.$v.payload.overload.$error },attrs:{"state":_vm.$v.payload.overload.$dirty ?
                !_vm.$v.payload.overload.$error : null},model:{value:(_vm.$v.payload.overload.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.overload, "$model", $$v)},expression:"$v.payload.overload.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('b-col',{attrs:{"lg":"3"}},[_c('b-form-group',{attrs:{"label":"Número de entradas"}},[_c('b-form-input',{class:{ 'form-group--error': _vm.$v.payload.number_in.$error },attrs:{"state":_vm.$v.payload.number_in.$dirty ?
                !_vm.$v.payload.number_in.$error : null},model:{value:(_vm.$v.payload.number_in.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.number_in, "$model", $$v)},expression:"$v.payload.number_in.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('b-col',{attrs:{"lg":"3"}},[_c('b-form-group',{attrs:{"label":"Bitola do fio"}},[_c('b-form-input',{class:{ 'form-group--error': _vm.$v.payload.gauge.$error },attrs:{"state":_vm.$v.payload.gauge.$dirty ?
                !_vm.$v.payload.gauge.$error : null},model:{value:(_vm.$v.payload.gauge.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.gauge, "$model", $$v)},expression:"$v.payload.gauge.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('b-col',{attrs:{"lg":"3"}},[_c('b-form-group',{attrs:{"label":"MC4 - Pares"}},[_c('b-form-input',{class:{ 'form-group--error': _vm.$v.payload.mc4.$error },attrs:{"state":_vm.$v.payload.mc4.$dirty ?
                !_vm.$v.payload.mc4.$error : null},model:{value:(_vm.$v.payload.mc4.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.mc4, "$model", $$v)},expression:"$v.payload.mc4.$model"}}),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('b-col',{attrs:{"lg":"3"}},[_c('b-form-group',{attrs:{"label":"Preço custo"}},[_c('money',_vm._b({staticClass:"form-control",class:{ 'form-group--error': _vm.$v.payload.price.$error },attrs:{"state":_vm.$v.payload.price.$dirty ?
                !_vm.$v.payload.price.$error : null},model:{value:(_vm.$v.payload.price.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload.price, "$model", $$v)},expression:"$v.payload.price.$model"}},'money',_vm.money,false)),_c('b-form-invalid-feedback',[_vm._v(" Preenchimento obrigatório ")])],1)],1),_c('div',{staticClass:"w-100 text-left mt-3"},[_c('b-button',{staticClass:"ml-3",attrs:{"variant":"success"},on:{"click":function($event){return _vm.save(false)}}},[_vm._v("Salvar")]),(!_vm.id)?_c('b-button',{staticClass:"ml-3",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save(true)}}},[_vm._v("Salvar e continuar")]):_vm._e()],1)],1)],1)],1)]),_c('b-row',{staticClass:"card mt-5"},[_c('div',{staticClass:"w-100"},[_c('h5',{staticClass:"title-card"},[_c('i',{staticClass:"fas fa-charging-station"}),_vm._v(" Componentes do inversor ")])]),_c('div',{staticClass:"w-100 p-3"},[_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":"Componente"}},[_c('b-form-select',{class:{ 'form-group--error': _vm.$v.payload_components.id.$error },attrs:{"state":_vm.$v.payload_components.id.$dirty ?
              !_vm.$v.payload_components.id.$error : null,"options":_vm.kitCompentesOptions},model:{value:(_vm.$v.payload_components.id.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload_components.id, "$model", $$v)},expression:"$v.payload_components.id.$model"}})],1)],1),_c('b-col',{attrs:{"lg":"2"}},[_c('b-form-group',{attrs:{"label":"Quantidade"}},[_c('b-form-input',{class:{ 'form-group--error': _vm.$v.payload_components.quantity.$error },attrs:{"state":_vm.$v.payload_components.quantity.$dirty ?
              !_vm.$v.payload_components.quantity.$error : null},model:{value:(_vm.$v.payload_components.quantity.$model),callback:function ($$v) {_vm.$set(_vm.$v.payload_components.quantity, "$model", $$v)},expression:"$v.payload_components.quantity.$model"}})],1)],1),_c('b-col',{staticClass:"align-btn"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.saveComponent}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" Adicionar Componente ")])],1)],1)],1),_c('div',{staticClass:"w-100 p-3 mt-3"},[_c('b-table',{attrs:{"responsive":"","bordered":"","items":_vm.kitCompentes,"fields":_vm.columsTableComponents},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-success my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Carregando...")])],1)]},proxy:true}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }