<template>
    <div>
      <b-row class="card mt-5">
        <div class="w-100">
          <h5 class="title-card">
            <i class="fas fa-charging-station"></i> Dados do inversor
          </h5>
        </div>
        <div class="w-100 p-3">
          <b-form>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Marca">
                  <b-form-input
                    :class="{ 'form-group--error': $v.payload.brand.$error }"
                    v-model="$v.payload.brand.$model"
                    :state="$v.payload.brand.$dirty ?
                    !$v.payload.brand.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Descrição">
                  <b-form-input
                    :class="{ 'form-group--error': $v.payload.description.$error }"
                    v-model="$v.payload.description.$model"
                    :state="$v.payload.description.$dirty ?
                    !$v.payload.description.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col lg="3">
                <b-form-group label="Potência (KW)">
                  <b-form-input
                    :class="{ 'form-group--error': $v.payload.power.$error }"
                    v-model="$v.payload.power.$model"
                    :state="$v.payload.power.$dirty ?
                    !$v.payload.power.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col lg="3">
                <b-form-group label="Overload (KWP)">
                  <b-form-input
                    :class="{ 'form-group--error': $v.payload.overload.$error }"
                    v-model="$v.payload.overload.$model"
                    :state="$v.payload.overload.$dirty ?
                    !$v.payload.overload.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col lg="3">
                <b-form-group label="Número de entradas">
                  <b-form-input
                    :class="{ 'form-group--error': $v.payload.number_in.$error }"
                    v-model="$v.payload.number_in.$model"
                    :state="$v.payload.number_in.$dirty ?
                    !$v.payload.number_in.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col lg="3">
                <b-form-group label="Bitola do fio">
                  <b-form-input
                    :class="{ 'form-group--error': $v.payload.gauge.$error }"
                    v-model="$v.payload.gauge.$model"
                    :state="$v.payload.gauge.$dirty ?
                    !$v.payload.gauge.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col lg="3">
                <b-form-group label="MC4 - Pares">
                  <b-form-input
                    :class="{ 'form-group--error': $v.payload.mc4.$error }"
                    v-model="$v.payload.mc4.$model"
                    :state="$v.payload.mc4.$dirty ?
                    !$v.payload.mc4.$error : null"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col lg="3">
                <b-form-group label="Preço custo">
                  <money
                    class="form-control"
                    :class="{ 'form-group--error': $v.payload.price.$error }"
                    v-model="$v.payload.price.$model"
                    :state="$v.payload.price.$dirty ?
                    !$v.payload.price.$error : null"
                    v-bind="money"
                  />
                  <b-form-invalid-feedback>
                    Preenchimento obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

                <div class="w-100 text-left mt-3">
                    <b-button class="ml-3" variant="success" @click="save(false)">Salvar</b-button>
                    <b-button class="ml-3" variant="primary" v-if="!id" @click="save(true)">Salvar e continuar</b-button>
                </div>
            </b-row>
          </b-form>
        </div>
      </b-row>

      <b-row class="card mt-5">
        <div class="w-100">
          <h5 class="title-card">
            <i class="fas fa-charging-station"></i> Componentes do inversor
          </h5>
        </div>
        <div class="w-100 p-3">
          <b-row>
            <b-col lg="4">
              <b-form-group label="Componente">
                <b-form-select
                  :class="{ 'form-group--error': $v.payload_components.id.$error }"
                  v-model="$v.payload_components.id.$model"
                  :state="$v.payload_components.id.$dirty ?
                  !$v.payload_components.id.$error : null"
                  :options="kitCompentesOptions"
                />
              </b-form-group>
            </b-col>

            <b-col lg="2">
              <b-form-group label="Quantidade">
                <b-form-input
                  :class="{ 'form-group--error': $v.payload_components.quantity.$error }"
                  v-model="$v.payload_components.quantity.$model"
                  :state="$v.payload_components.quantity.$dirty ?
                  !$v.payload_components.quantity.$error : null"
                />
              </b-form-group>
            </b-col>
            <b-col class="align-btn">
              <b-button variant="primary" @click="saveComponent">
                <i class="fas fa-plus"></i> Adicionar Componente
              </b-button>
            </b-col>
          </b-row>
        </div>

        <div class="w-100 p-3 mt-3">
          <b-table
            responsive
            bordered
            :items="kitCompentes"
            :fields="columsTableComponents"
          >
            <template #table-busy>
              <div class="text-center text-success my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Carregando...</strong>
              </div>
            </template>
          </b-table>
        </div>
      </b-row>
    </div>
  </template>

<script>
import { required } from 'vuelidate/lib/validators';
import MessagesMixin from '@/mixins/messages-mixin';
import QuestionMixin from '@/mixins/question-mixin';
import ErrorMixin from '@/mixins/error-mixin';
import { Money } from 'v-money';
import InverterService from '../../../Services/InverterService';
import ComponentsService from '../../../Services/ComponentsService';

export default {
  components: { Money },
  props: ['id'],
  mixins: [MessagesMixin, QuestionMixin, ErrorMixin],
  data() {
    return {
      columsTableComponents: ComponentsService.getFieldsTableComponentsInverter(),
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false, /* doesn't work with directive */
      },
      kitCompentes: [],
      payload: {
        description: '',
        brand: '',
        number_in: '',
        power: '',
        overload: '',
        gauge: '',
        mc4: '',
        price: '',
      },
      kitCompentesOptions: [
        {
          text: 'Selecione',
          value: '',
        },
      ],
      payload_components: {
        id: '',
        quantity: '',
      },
    };
  },
  validations: {
    payload: {
      description: { required },
      brand: { required },
      number_in: { required },
      power: { required },
      overload: { required },
      gauge: { required },
      mc4: { required },
      price: { required },
    },
    payload_components: {
      id: { required },
      quantity: { required },
    },
  },
  methods: {
    async getInverter() {
      try {
        const response = await InverterService.get(this.id);
        this.payload = response.data;
        this.kitCompentes = response.data.components.map((item) => ({
          id: item.id,
          description: item.kit_component.description,
          quantity: item.quantity,
          price: this.$options.filters.currency(item.quantity * item.kit_component.price),
        }));
      } catch (error) {
        console.log(error);
      }
    },
    async getComponents() {
      const { data } = await ComponentsService.getList();
      data.forEach((item) => {
        this.kitCompentesOptions.push({
          text: item.description,
          value: item.id,
          price: item.price,
        });
      });
    },
    resetForm() {
      this.payload.description = '';
      this.payload.number_in = '';
      this.payload.power = '';
      this.payload.overload = '';
      this.payload.gauge = '';
      this.payload.mc4 = '';
      this.payload.price = '';
      this.$v.payload.$reset();
    },
    saveComponent() {
      if (!this.$v.payload_components.$invalid) {
        const component = this.kitCompentesOptions.find((item) => item.value === this.payload_components.id);
        this.kitCompentes.push({
          id: component.value,
          description: component.text,
          quantity: this.payload_components.quantity,
          price: this.$options.filters.currency(this.payload_components.quantity * component.price),
        });

        this.payload_components = {
          id: '',
          quantity: '',
        };
        this.$v.payload_components.$reset();
      }
    },
    async save(continueSave) {
      this.$v.payload.$touch();
      if (!this.$v.payload.$invalid) {
        const payload = {
          id: this.id,
          inverter: this.payload,
          components: this.kitCompentes,
        };

        try {
          const { data } = await this.id ? InverterService.edit(payload) : InverterService.save(payload);
          console.log(data);

          if (this.id) {
            this.messageSuccess('Inversor editado com sucesso!');
          } else {
            this.messageSuccess('Inversor cadastrado com sucesso!');
          }

          if (continueSave) {
            this.resetForm();
          } else {
            this.$router.push({ name: 'inverter' });
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  created() {
    if (this.id) {
      this.getInverter();
    }

    this.getComponents();
  },
};
</script>
